import React from 'react';

import { Grid } from '@material-ui/core';

import checkImage from '../../images/check.svg';

export const Usage = () => (
  <div
    style={{
      backgroundColor: '#EDECE5',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    }}
  >
    <h2 style={{ textAlign: 'center', paddingTop: '2rem' }}>
      Setzen Sie CrefoTrust hier ein:
    </h2>
    <Grid
      container
      justifyContent='center'
      spacing={1}
      style={{
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '1rem',
      }}
    >
      <Grid container item sm={12} md={6} lg={3} style={{ maxWidth: '400px' }}>
        <Grid container style={{ textAlign: 'center', flexWrap: 'noWrap' }}>
          <img
            src={checkImage}
            alt=''
            style={{
              width: '40px',
              height: '40px',
            }}
          />{' '}
          <p
            style={{
              marginLeft: '0.7rem',
              marginTop: '1rem',
              lineHeight: '1.5',
            }}
          >
            Anmelde- und Kaufvorgänge
          </p>
        </Grid>
      </Grid>
      <Grid container item sm={12} md={6} lg={3} style={{ maxWidth: '400px' }}>
        <Grid container style={{ textAlign: 'center', flexWrap: 'noWrap' }}>
          <img
            src={checkImage}
            alt=''
            style={{
              width: '40px',
              height: '40px',
            }}
          />{' '}
          <p
            style={{
              textAlign: 'left',
              marginLeft: '0.7rem',
              lineHeight: '1.5',
              wordWrap: 'break-word',
              overflow: 'hidden',
              marginTop: '1rem',
            }}
          >
            Onboarding- und Registrierungsprozesse
          </p>
        </Grid>
      </Grid>
      <Grid container item sm={12} md={6} lg={3} style={{ maxWidth: '400px' }}>
        <Grid container style={{ flexWrap: 'noWrap' }}>
          <img
            src={checkImage}
            alt=''
            style={{
              width: '40px',
              height: '40px',
            }}
          />
          <p
            style={{
              marginLeft: '0.7rem',
              lineHeight: '1.5',
              textAlign: 'left',
              marginTop: '1rem',
              overflow: 'hidden',
            }}
          >
            {' '}
            Antragsstrecken &amp; Bestellabläufe
          </p>
        </Grid>
      </Grid>
      <Grid container item sm={12} md={6} lg={3} style={{ maxWidth: '400px' }}>
        <Grid container style={{ flexWrap: 'noWrap' }}>
          <img
            src={checkImage}
            alt=''
            style={{
              width: '40px',
              height: '40px',
            }}
          />
          <p
            style={{
              marginLeft: '0.7rem',
              marginTop: '1rem',
              lineHeight: '1.5',
              overflow: 'hidden',
            }}
          >
            Authentifizierungsverfahren
          </p>
        </Grid>
      </Grid>
    </Grid>
  </div>
);

import React from 'react';

import { Grid, Hidden } from '@material-ui/core';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import termsAndConditions from '../../assets/agb.crefotrust.pdf';
import DataAgreement from '../../assets/dataagreement.aba3b4a8.pdf';

export const Footer = () => (
  <div
    style={{
      backgroundColor: '#7F7F7F',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      color: 'white',
      fontSize: '12px',
    }}
  >
    <Grid container justifyContent='space-between' wrap='wrap' spacing={1}>
      <Grid
        item
        sm={3}
        xs={12}
        style={{
          paddingTop: '1rem',
          textAlign: 'center',
        }}
      >
        <p style={{ fontSize: '12px' }}>
          © {new Date().getFullYear()} Verband der Vereine Creditreform e.V.
        </p>
      </Grid>
      <Grid item sm={3} xs={12}>
        <div style={{ marginLeft: 'auto' }}>
          <div style={{ paddingTop: '1rem', textAlign: 'center' }}>
            <a target='_blank' href='/imprint' style={{ color: 'white' }}>
              Impressum
            </a>
            <span> | </span>
            <a
              target='_blank'
              href={DataAgreement}
              style={{ color: 'white' }}
              rel='noreferrer'
            >
              Datenschutz
            </a>
            <span> | </span>
            <a
              target='_blank'
              href={termsAndConditions}
              style={{ color: 'white' }}
              rel='noreferrer'
            >
              AGB
            </a>
          </div>
        </div>
      </Grid>
      <Hidden xsDown>
        <Grid
          item
          sm={3}
          xs={12}
          style={{
            paddingTop: '1rem',
            textAlign: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginRight: 'auto',
            }}
          >
            <Link
              to='/'
              style={{ color: 'white' }}
              aria-label='An den Seitenanfang scrollen'
            >
              <div style={{ display: 'flex' }}>
                <StaticImage
                  src='../../images/crefo-up.png'
                  alt=''
                  style={{
                    width: '15px',
                    height: '9px',
                    marginTop: '7px',
                    marginRight: '5px',
                  }}
                />
                <p style={{ fontSize: '12px', textAlign: 'right' }}>
                  zum Seitenanfang
                </p>
              </div>
            </Link>
          </div>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid
          item
          sm={3}
          xs={12}
          style={{
            paddingTop: '1rem',
            textAlign: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginRight: 'auto',
            }}
          >
            <Link
              to='/'
              aria-label='An den Seitenanfang scrollen'
              style={{ color: 'white' }}
            >
              <div style={{ display: 'flex', marginTop: '1rem' }}>
                <StaticImage
                  src='../../images/crefo-up.png'
                  alt=''
                  style={{
                    width: '15px',
                    height: '9px',
                    marginTop: '7px',
                    marginRight: '5px',
                  }}
                />
                <p style={{ fontSize: '12px', textAlign: 'right' }}>
                  zum Seitenanfang
                </p>
              </div>
            </Link>
          </div>
        </Grid>
      </Hidden>
    </Grid>
  </div>
);

import React from 'react';

import { Grid } from '@material-ui/core';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

export const Interested = () => (
  <div
    className='content-spacer'
    style={{
      backgroundColor: '#EDECE5',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    }}
  >
    <Grid container justifyContent='center' wrap='wrap' spacing={2}>
      {/* Kontaktaufnahme  */}
      <Grid
        item
        md={6}
        sm={12}
        style={{
          marginBottom: '2rem',
          maxWidth: '600px',
          textAlign: 'center',
        }}
      >
        <h1 style={{ marginBottom: '2rem' }}>
          Haben Sie Interesse an CrefoTrust?
        </h1>
        <Link
          to='/#kontaktformular'
          className='btn btn-default'
          style={{ width: '80%', marginBottom: '2rem' }}
        >
          Kontakt aufnehmen
        </Link>
      </Grid>

      {/* Bilder  */}
      <Grid
        item
        md={6}
        sm={12}
        style={{
          maxWidth: '600px',
        }}
      >
        <Grid container wrap='wrap' spacing={2}>
          <Grid
            item
            md={4}
            xs={12}
            style={{
              marginLeft: '2rem',
              marginRight: '2rem',
              textAlign: 'center',
            }}
          >
            <StaticImage src='../../images/daniel.png' alt='' />
            <p style={{ marginTop: '1rem' }}>Daniel Guschauski</p>
            <p className='text-small' style={{ marginTop: '-1rem' }}>
              Produktmanager
            </p>
            <Link to='/#kontaktformular'>
              <i
                className='crefo-brand-icon icon-envelope  icon-color-blue'
                aria-hidden='true'
              />
            </Link>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{
              marginLeft: '2rem',
              marginRight: '2rem',
              textAlign: 'center',
              marginBottom: '2rem',
            }}
          >
            <StaticImage src='../../images/antje.png' alt='' />
            <p style={{ marginTop: '1rem' }}>Claudius Schimanski</p>
            <p className='text-small' style={{ marginTop: '-1rem' }}>
              Kunden- und Partnermanagement
            </p>
            <Link to='/#kontaktformular'>
              <i
                className='crefo-brand-icon icon-envelope  icon-color-blue'
                aria-hidden='true'
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </div>
);

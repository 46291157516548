import React, { useState, useEffect } from 'react';
// import React, { useState } from 'react';

import { Grid, Button, ClickAwayListener } from '@material-ui/core';
import axios from 'axios';
import ShowMoreText from 'react-show-more-text';
import validator from 'validator';

export const B2bContact = () => {
  const [email, setEmail] = useState('');
  const [mailError, setMailError] = useState(false);
  const [mail, setMail] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [selected, setSelected] = useState({
    value: undefined,
    text: undefined,
  });
  const [expanded, setExpanded] = useState(false);

  // Komponente für Infobox unter Mailabsenden
  const CrefoAlert = () =>
    showAlert ? (
      <div
        className={'crefo-ui-alert'.concat(mailError ? ' error' : ' success')}
        style={{ marginTop: 20 }}>
        <span className='alert-close' onClick={() => setShowAlert(false)} />
        {mail}
      </div>
    ) : (
      <></>
    );

  const handleSelect = (e) => {
    setSelected({
      value: e.target?.attributes.value?.value,
      text: e.target?.textContent,
    });
    setExpanded(false);
  };

  const sendMail = async () => {
    const requestType = selected.value;
    const data = { requestFor: requestType, to: email };
    const headers = {
      'Content-Type': 'application/json',
    };
    const response = await axios.post('/contact', JSON.stringify(data), {
      headers,
    });

    // console.log(response)

    if (response.status === 200) {
      setMail('Unser Team von CrefoTrust meldet sich bei Ihnen.');
      setShowAlert(true);
    } else {
      setMailError(true);
      setMail('Beim Versenden ist ein Fehler aufgetreten!'); // passiert, wenn Absenden nicht funktioniert hat
      setShowAlert(true);
    }
  };

  const [buttonDisabled, setButtonDisabled] = useState(true)

  // const useButtonDisabled = () => {
  //  const [buttonDisabled, setButtonDisabled] = useState(true);
  // console.log(`Creation-EMAIL: ${email}`)

  useEffect(() => {
    if (email.length > 0 && validator.isEmail(email)) {  
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }   
  },[buttonDisabled, email]);

  
  return (
    <div
      className='content-spacer'
      style={{
        backgroundColor: 'white',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}>
      <Grid container justifyContent='center' spacing={6}>
        <Grid
          item
          sm={12}
          md={6}
          style={{
            paddingBottom: '0% 0% 56.25%',
            marginBottom: '2rem',
            maxWidth: '600px',
            marginLeft: '2rem',
            marginRight: '2rem',
          }}>
          <h1
            className='secondaryHeader'
            style={{
              textAlign: 'left',
            }}>
            Verifizierte Informationen im B2B Geschäft
          </h1>
          <p>
            Mit <b>CrefoTrust</b> bieten wir Ihnen eine einfach zu integrierende{' '}
            <b>Vertrauenslösung</b>, die genau auf Ihren Informationsbedarf
            angepasst ist. Innerhalb weniger Klicks können Ihnen Kunden
            gewünschte Nachweise zur eigenen Person und Firma übermitteln.
          </p>

          <ShowMoreText
            /* Default options */
            lines={1}
            more='Mehr anzeigen'
            less='Weniger anzeigen'
            anchorClass=''
            expanded={false}>
            <p
              style={{
                fontSize: '15px',
                lineHeight: '26px',
                fontFamily: 'Open Sans',
              }}>
              Dafür prüfen wir für Ihr Unternehmen, ob die getätigten
              Personenangaben korrekt sind, die Unternehmensdaten stimmen und
              eine Vertretungsberechtigung besteht. Ob in Kauf-,
              Registrierungs-, Leasing-, Factoring-, Kredit-, oder Supply Chain
              Prozessen – der verifizierte Informationsaustausch zwischen
              Unternehmen und Endkunden war noch nie so{' '}
              <b>sicher und komfortabel</b>!
            </p>
          </ShowMoreText>
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          style={{ maxWidth: '600px', marginLeft: '-1rem' }}>
          <h1
            className='secondaryHeader'
            style={{
              textAlign: 'left',
            }}>
            <a id='kontaktformular'>An CrefoTrust interessiert?</a>
          </h1>
          {/* Kontaktaufnahme Grund Auswahlfeld */}

          <div className='crefo-input crefo-ui-select' data-crefo-ui='true'>
            <div className='input-group'>
              <label htmlFor='test-select'>Ich möchte</label>
              <ClickAwayListener onClickAway={() => setExpanded(false)}>
                <div
                  className='select-group'
                  onClick={() => setExpanded(!expanded)}>
                  <div
                    className={
                      expanded ? 'input-wrapper show-options' : 'input-wrapper'
                    }>
                    <div className='select-selected'>
                      {selected.text ? selected.text : 'Bitte wählen...'}
                    </div>
                    <div
                      className={
                        expanded ? 'select-items' : 'select-items select-hide'
                      }>
                      <div value='Neuigkeiten' onClick={(e) => handleSelect(e)}>
                        Neuigkeiten zu CrefoTrust erfahren
                      </div>
                      <div value='Demo' onClick={(e) => handleSelect(e)}>
                        Einen Demo-Termin vereinbaren
                      </div>
                      <div value='Partner' onClick={(e) => handleSelect(e)}>
                        Mich zu einer möglichen Partnerschaft austauschen
                      </div>
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
            </div>
          </div>
          {/* E-Mail Inputfeld */}
          <div className='crefo-input'>
            <div className='input-group'>
              {/* <label for="name">E-Mail-Adresse</label> */}
              <div className='input-wrapper'>
                <input
                  type='text'
                  placeholder='Ihre E-Mail-Adresse'
                  id='name'
                  minLength='3'
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
          </div>
          <Button
            disabled={buttonDisabled}
            onClick={sendMail}
            className='btn btn-default'
            style={{ minWidth: '100%' }}>
            Absenden
          </Button>
          {/* <p style={{ textAlign: "right", marginTop: "1rem" }}>{mail}</p> */}
          {/* Infobox - nur zeigen, wenn absenden -> eigene Komponente schreiben; */}
          <CrefoAlert />
        </Grid>
      </Grid>
    </div>
  );
};

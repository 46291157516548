import React from 'react';

import PropTypes from 'prop-types';

import logo from '../../images/Guardian.svg';

// eslint-disable-next-line no-unused-vars
export const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `white`,
      marginBottom: `1.45rem`,
      marginTop: '1.45rem',
    }}
  >
    <div
      style={{
        display: `flex`,
        justifyContent: `space-between`,
      }}
    >
      <div style={{ marginLeft: '2rem' }}>
        <img src={logo} alt='CrefoTrust Logo' width="40%" />
      </div>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

import React from 'react';

import { Grid } from '@material-ui/core';

export const Technologies = () => (
  <div
    className='content-spacer'
    style={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      backgroundColor: 'white',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
    }}
  >
    <h1>Mit welchen Technologien arbeitet CrefoTrust?</h1>
    {/* Technologie 1 */}
    <Grid
      container
      justifyContent='center'
      wrap='wrap'
      spacing={6}
      style={{
        marginTop: '2rem',
        marginBottom: '2rem',
        maxWidth: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Grid
        item
        sm={12}
        md={4}
        style={{
          marginBottom: '2rem',
        }}
      >
        <h2>Dezentrale Identität</h2>
        <p
          style={{
            marginTop: '2rem',
            display: 'block',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          Mit einer Dezentralen Identität (DID) weisen wir{' '}
          <b>Identitäten und zugehörige Informationen</b> von Personen und
          Unternehmen aus.
        </p>
      </Grid>

      {/* Technologie 2 */}
      <Grid
        item
        sm={12}
        md={4}
        style={{
          marginBottom: '2rem',
        }}
      >
        <h2>Creditreform Datenpool</h2>
        <p
          style={{
            marginTop: '2rem',
            display: 'block',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          Die <b>hohe Datenqualität</b> von CrefoTrust ermöglicht es,
          verifizierte Informationen auszustellen und wiederverwendbar zu
          machen.
        </p>
      </Grid>
      {/* Technologie 3 */}
      <Grid
        item
        sm={12}
        md={4}
        style={{
          marginBottom: '2rem',
        }}
      >
        <h2>Informationsnachweise</h2>
        <p
          style={{
            marginTop: '2rem',
            display: 'block',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          Wir orientieren uns an den neuesten <b>internationalen Standards</b>{' '}
          und stellen sogenannte Verifiable Credentials aus.
        </p>
      </Grid>
    </Grid>
  </div>
);

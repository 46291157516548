import React from 'react';

import { Grid } from '@material-ui/core';

import personVerifiziertImage from '../../images/person-verifiziert.svg';
import unternehmenVerifiziertImage from '../../images/unternehmen-verifiziert.svg';
import vertretungsberechtigungVerifiziertImage from '../../images/vertretungsberechtigung-verifiziert.svg';

export const VerifiedData = () => (
  <div
    style={{
      backgroundColor: '#EDECE5',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '2rem',
    }}
  >
    <h2 style={{ textAlign: 'center', paddingTop: '2rem' }}>
      Erhalten Sie diese Informationen verifiziert und nachgewiesen:
    </h2>
    <Grid
      container
      justifyContent='center'
      spacing={4}
      style={{
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '1rem',
        overflow: 'hidden',
      }}
    >
      <Grid container item sm={12} md={4} style={{ maxWidth: '400px' }}>
        <Grid container style={{ textAlign: 'center', flexWrap: 'noWrap' }}>
          <img
            src={personVerifiziertImage}
            alt=''
            style={{
              width: '50px',
              height: '50px',
            }}
          />
          <p
            style={{
              marginLeft: '0.7rem',
              marginTop: '1rem',
              lineHeight: '1.5',
              overflow: 'hidden',
            }}
          >
            Personenangaben
          </p>
        </Grid>
      </Grid>
      <Grid container item sm={12} md={4} style={{ maxWidth: '400px' }}>
        <Grid container style={{ textAlign: 'center', flexWrap: 'noWrap' }}>
          <img
            src={unternehmenVerifiziertImage}
            alt=''
            style={{
              width: '50px',
              height: '50px',
            }}
          />
          <p
            style={{
              textAlign: 'left',
              marginLeft: '0.7rem',
              lineHeight: '1.5',
              wordWrap: 'break-word',
              marginTop: '1rem',
              overflow: 'hidden',
            }}
          >
            Unternehmensstammdaten
          </p>
        </Grid>
      </Grid>
      <Grid container item sm={12} md={4} style={{ maxWidth: '400px' }}>
        <Grid container style={{ flexWrap: 'noWrap' }}>
          <img
            src={vertretungsberechtigungVerifiziertImage}
            alt=''
            style={{
              width: '50px',
              height: '50px',
            }}
          />
          <p
            style={{
              marginLeft: '0.7rem',
              lineHeight: '1.5',
              textAlign: 'left',
              marginTop: '1rem',
              overflow: 'hidden',
            }}
          >
            {' '}
            Vertretungsberechtigungen
          </p>
        </Grid>
      </Grid>
    </Grid>
  </div>
);

import React from 'react';

import { Grid, Hidden } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';

import List1Image from '../../images/list1.png';
import List2Image from '../../images/list2.png';
import List3Image from '../../images/list3.png';
import List4Image from '../../images/list4.png';

export const Explanation = () => (
  <>
    <div
      className='content-spacer'
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        backgroundColor: '#EDECE5',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '2rem',
      }}
    >
      <Hidden smDown>
        <div
          style={{
            maxWidth: '1150px',
            marginLeft: '2rem',
            marginRight: '2rem',
          }}
        >
          <h1
            className='secondaryHeader'
            style={{
              textAlign: 'left',
            }}
          >
            Vertrauen schaffen: So funktioniert CrefoTrust
          </h1>
          <p
            style={{
              textAlign: 'left',
            }}
          >
            Wir stellen das technologische Fundament, um geprüfte Daten über die
            Grenzen einzelner Anbieter hinweg <b>wiederverwendbar</b> zu machen.
            Sowohl die Identität, als auch die Firmeninformationen werden dafür
            in einer <b>Dezentralen Identität (DID)</b> fälschungssicher
            gespeichert und verwaltet. So profitieren Sie und auch Ihre Kunden,
            die nur einmalig einen kurzen Verifizierungsprozess durchlaufen.
          </p>
          <p>
            Dabei müssen Ihre Nutzer weder Formulare ausfüllen, noch Dokumente
            hochladen!
          </p>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div
          style={{
            maxWidth: '600px',
            marginLeft: '2rem',
            marginRight: '2rem',
          }}
        >
          <h1
            className='secondaryHeader'
            style={{
              textAlign: 'left',
              marginTop: '3rem',
            }}
          >
            Vertrauen schaffen: So funktioniert CrefoTrust
          </h1>
          <p
            style={{
              textAlign: 'left',
            }}
          >
            Wir stellen das technologische Fundament, um geprüfte Daten über die
            Grenzen einzelner Anbieter hinweg <b>wiederverwendbar</b> zu machen.
            Sowohl die Identität, als auch die Firmeninformationen werden dafür
            in einer <b>Dezentralen Identität (DID)</b> fälschungssicher
            gespeichert und verwaltet. So profitieren Sie und auch Ihre Kunden,
            die nur einmalig einen kurzen Verifizierungsprozess durchlaufen.
          </p>
        </div>
      </Hidden>
    </div>

    {/* 1. Bild */}
    <div
      className='content-spacer'
      style={{
        backgroundColor: '#EDECE5',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Grid container justifyContent='center' wrap='wrap-reverse' spacing={6}>
        <Hidden xsDown>
          <Grid
            item
            sm={6}
            xs={12}
            style={{ marginTop: '2rem', maxWidth: '500px' }}
          >
            <StaticImage src='../../images/macImage1.png' alt='' />
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item xs={12} style={{ marginTop: '2rem', maxWidth: '300px' }}>
            <StaticImage src='../../images/macImageMobile1.png' alt='' />
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          style={{
            paddingBottom: '0% 0% 56.25%',

            marginTop: '2rem',
            maxWidth: '600px',
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: '3rem',
          }}
        >
          <img
            src={List1Image}
            alt=''
            style={{
              width: '30px',
              height: '30px',
              marginRight: '1rem',
            }}
          />
          <p>
            An passender Stelle in Ihrem Anmeldungs-, Bestell- oder
            Verifizierungsprozess integrieren wir uns nahtlos auf Ihrer
            digitalen Plattform.
          </p>
        </Grid>
      </Grid>
    </div>

    {/* 2. Bild */}
    <div
      className='content-spacer'
      style={{
        backgroundColor: '#EDECE5',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Grid container justifyContent='center' wrap='wrap-reverse' spacing={6}>
        <Hidden xsDown>
          <Grid
            item
            sm={6}
            xs={12}
            style={{ marginTop: '2rem', maxWidth: '500px' }}
          >
            <StaticImage src='../../images/macImage2.png' alt='' />
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item xs={12} style={{ marginTop: '2rem', maxWidth: '300px' }}>
            <StaticImage src='../../images/macImageMobile2.png' alt='' />
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          style={{
            paddingBottom: '0% 0% 56.25%',

            marginTop: '2rem',
            maxWidth: '600px',
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: '3rem',
          }}
        >
          <img
            src={List2Image}
            alt=''
            style={{
              width: '30px',
              height: '30px',
              marginRight: '1rem',
            }}
          />
          <p>
            Ihre Kunden wählen sich in einem datengestützten Prozess als
            vertretungsberechtigte Person eines Unternehmens aus. Im nächsten
            Schritt werden diese Angaben verifiziert.
          </p>
        </Grid>
      </Grid>
    </div>

    {/* 3. Bild */}
    <div
      className='content-spacer'
      style={{
        backgroundColor: '#EDECE5',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Grid container justifyContent='center' wrap='wrap-reverse' spacing={6}>
        <Hidden xsDown>
          <Grid
            item
            sm={6}
            xs={12}
            style={{
              marginTop: '2rem',
              maxWidth: '500px',
              marginBottom: '3rem',
            }}
          >
            <StaticImage src='../../images/macImage3.png' alt='' />
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid
            item
            xs={12}
            style={{
              marginTop: '2rem',
              maxWidth: '300px',
              marginBottom: '3rem',
            }}
          >
            <StaticImage src='../../images/macImageMobile3.png' alt='' />
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          style={{
            paddingBottom: '0% 0% 56.25%',

            marginTop: '2rem',
            maxWidth: '600px',
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: '3rem',
          }}
        >
          <img
            src={List3Image}
            alt=''
            style={{
              width: '30px',
              height: '30px',
              marginRight: '1rem',
            }}
          />
          <p>
            In einem zertifizierten VideoIdent-Verfahren weisen Ihre Kunden ihre
            Angaben mit einem gültigen Ausweis nach und sind anschließend als
            verifizierte Personen bei CrefoTrust gespeichert.
          </p>
        </Grid>
      </Grid>
    </div>

    {/* 4. Bild */}
    <div
      className='content-spacer'
      style={{
        backgroundColor: '#EDECE5',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Grid container justifyContent='center' wrap='wrap-reverse' spacing={6}>
        <Hidden xsDown>
          <Grid
            item
            sm={6}
            xs={12}
            style={{
              marginTop: '2rem',
              maxWidth: '500px',
              marginBottom: '3rem',
            }}
          >
            <StaticImage src='../../images/macImage4.png' alt='' />
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid
            item
            xs={12}
            style={{
              marginTop: '1rem',
              maxWidth: '300px',
              marginBottom: '3rem',
            }}
          >
            <StaticImage src='../../images/macImageMobile4.png' alt='' />
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          style={{
            paddingBottom: '0% 0% 56.25%',

            marginTop: '2rem',
            maxWidth: '600px',
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: '3rem',
          }}
        >
          <img
            src={List4Image}
            alt=''
            style={{
              width: '30px',
              height: '30px',
              marginRight: '1rem',
            }}
          />
          <p>
            CrefoTrust stellt Ihnen die verifizierten und geprüften
            Informationen Ihrer Kunden in Echtzeit bereit. <br />
            Den Nachweis können Ihre Kunden künftig auf all Ihren digitalen
            Angeboten sowie den Plattformen anderer Unternehmen einsetzen und
            wiederverwenden!
          </p>
        </Grid>
      </Grid>
    </div>

    {/* 5. Bild */}
    <div
      className='content-spacer'
      style={{
        backgroundColor: '#EDECE5',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Grid container justifyContent='center' wrap='wrap-reverse' spacing={6}>
        <Hidden xsDown>
          <Grid
            item
            sm={6}
            xs={12}
            style={{
              marginTop: '2rem',
              minWidth: '70%',
              marginBottom: '3rem',
            }}
          >
            <StaticImage src='../../images/macImageEnd.png' alt='' />
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid
            item
            xs={12}
            style={{
              marginTop: '2rem',
              maxWidth: '300px',
              marginBottom: '3rem',
            }}
          >
            <StaticImage src='../../images/macImageMobileEnd.png' alt='' />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  </>
);

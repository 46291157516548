import React, { useRef } from 'react';

import { Hidden } from '@material-ui/core';

import KeyVideoMobile from '../../assets/keyvisual-mobile.mp4';
import KeyVideo from '../../assets/keyvisual.mp4';

export const KeyVisual = () => {
  // play video 2 times as fast
  const videoRef = useRef();
  const mobileVideoRef = useRef();

  const setPlayBack = (type) => {
    if (type === 'mobile') mobileVideoRef.current.playbackRate = 1.5;
    else if (type === 'regular') videoRef.current.playbackRate = 1.5;
  };

  return (
    <div>
      <h1
        className='marketing'
        style={{
          textAlign: 'center',
          maxWidth: '50%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <span>Sicher wissen, mit wem Sie online Geschäfte abschließen.</span>
      </h1>

      <Hidden smUp>
        <video
          width='100%'
          autoPlay
          muted
          playsInline
          ref={mobileVideoRef}
          onCanPlay={() => setPlayBack('mobile')}
        >
          <source src={KeyVideoMobile} type='video/mp4' />
        </video>
      </Hidden>
      <Hidden xsDown>
        <video
          width='100%'
          autoPlay
          muted
          playsInline
          ref={videoRef}
          onCanPlay={() => setPlayBack('regular')}
        >
          <source src={KeyVideo} type='video/mp4' />
        </video>
      </Hidden>
    </div>
  );
};

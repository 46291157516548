import React from 'react';

import { B2bContact } from '../components/b2b-contact/b2b-contact.component';
import { Explanation } from '../components/explanation/explanation.component';
import { Footer } from '../components/footer/footer.component';
import { Header } from '../components/header/header.component';
import { Interested } from '../components/interested/interested.component';
import { KeyVisual } from '../components/key-visual/key-visual.component';
import { Layout } from '../components/layout/layout.component';
import { SEO } from '../components/seo/seo.component';
import '../styles/crefo/fonts.css';
import '../styles/crefo/crefo-ui.min.css';
import './app.css';
import { Technologies } from '../components/technologies/technologies.component';
import { Usage } from '../components/usage/usage.component';
import { VerifiedData } from '../components/verified-data/verified-data.component';
import { Why } from '../components/why/why.component';

const IndexPage = () => (
  <Layout>
    <Header siteTitle='CrefoTrust - Ihr Partner für digitale Identitäten' />
    <SEO title='CrefoTrust' />
    <a id='header' />
    <KeyVisual />

    <Usage />

    <Why />

    <VerifiedData />

    <B2bContact />

    <Explanation />

    <Technologies />

    <Interested />

    <Footer />
  </Layout>
);

export default IndexPage;

import React from 'react';

import { Grid } from '@material-ui/core';

export const Why = () => (
  <div
    className='content-spacer'
    id='why'
    style={{
      backgroundColor: 'white',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    }}
  >
    <Grid container justifyContent='center' spacing={6}>
      <Grid
        item
        sm={12}
        md={6}
        style={{
          paddingBottom: '0% 0% 56.25%',
          marginBottom: '2rem',
          maxWidth: '600px',
          display: 'flex',
        }}
      >
        <iframe
          title='animation'
          top='0'
          left='0'
          width='100%'
          height='auto'
          border='0'
          src='https://www.youtube.com/embed/z99hd4QV7wM'
        />
      </Grid>
      <Grid
        item
        sm={12}
        md={6}
        style={{
          maxWidth: '600px',
          marginTop: '-10px',
          marginLeft: '2rem',
          marginRight: '2rem',
        }}
      >
        <h1 className='secondaryHeader'>
          Schluss mit komplizierten Verifikationsverfahren.
        </h1>
        <p>
          Um <b>Betrug</b> und Identitätsdiebstahl zu verhindern, müssen
          Prüfprozesse im Internet besonders sicher sein – ohne dabei an
          Usability zu verlieren. Gerade im B2B-Geschäft sind{' '}
          <b>verlässliche Informationen</b> zu Personen und Unternehmen
          entscheidend:
        </p>
        <ul className='list-unordered'>
          <li style={{ marginBottom: '10px' }}>
            Handelt es sich bei einem Nutzer wirklich um die Person, für die er
            sich ausgibt?
          </li>
          <li style={{ marginBottom: '10px' }}>
            Vertritt die Person tatsächlich die angegebene Firma?
          </li>
        </ul>
      </Grid>
    </Grid>
  </div>
);
